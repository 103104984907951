/* eslint-disable jsdoc/require-jsdoc */
import React, {
  useState,
  useContext,
  useRef,
  useLayoutEffect,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import Script from 'next/script';
import get from 'lodash.get';

import { setTriggerTop as setTriggerTopAction } from 'redux/modules/layout';
import { bulkUpdate } from 'redux/modules/navbar';

import Breakpoints from 'lib/Breakpoints';
import { FeatureFlagContext } from 'lib/ContextTypes';
import HeadlinePropType from 'lib/CustomPropTypes/headline';
import { hasSingleNav } from 'lib/hasSingleNav';
import loadScript from 'lib/loadScript';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  MYNEWS_ENABLED,
  ENABLE_ELECTIONS_NAV_THEME,
  SHOW_MOBILE_WEB_NAV,
  LOCAL_NAV_INSERT_OVERRIDE,
  TRENDING_NAV,
  BEDROCK_API_ENABLED,
} from 'lib/brandFeatures';
import MiniPlayerTease from 'components/MiniPlayerTease';
import { AdSponsor } from 'components/Ad/Sponsor';
import { Save } from 'components/SocialShareMenu/Save';

import {
  USA,
  GEOLOCATION_COOKIE,
} from 'lib/myNewsConstants';

import { HeaderFallback } from './Fallback';
import { HeaderNavbar } from './Navbar';
import { ElectionsNav } from './ElectionsNav';
import { SkipToContentOverlay } from './SkipToContentOverlay';
import { TrendingNav } from './TrendingNav';
import { onLocalInsertShow, onGlobalInsertShow, hasTeaseBelowNav } from './Header.utils';
import './styles.themed.scss';

const mapStateToProps = ({
  navbar,
  article,
  recipe,
  shared,
}) => {
  let taxonomy;

  const computedContentId = get(article, 'content[0].id', '');
  if (recipe?.current) {
    taxonomy = get(recipe, ['current', 'taxonomy', 'primaryTopic', 'slug'], null);
  } else {
    taxonomy = get(article, ['content', 0, 'taxonomy', 'primaryTopic', 'slug'], null);
  }

  return ({
    headline: navbar.headline,
    shareUrl: navbar.shareUrl,
    shortcuts: navbar.shortcutsVisible,
    taxonomy,
    contentId: computedContentId,
    vertical: get(shared, 'vertical', 'news'),
  });
};

export const getSocialShareUrl = (path, vertical) => {
  if (/^\//.test(path)) {
    switch (vertical) {
      case 'today':
        return `https://www.today.com${path}`;
      case 'msnbc':
        return `https://www.msnbc.com${path}`;
      case 'noticias':
      case 'deportes':
        return `https://www.telemundo.com${path}`;
      case 'entretenimiento':
        return `https://www.telemundo.com${path}`;
      case 'shows':
        return `https://www.telemundo.com${path}`;
      case 'telemundo':
        return `https://www.telemundo.com${path}`;
      default:
        return `https://www.nbcnews.com${path}`;
    }
  }
  return path;
};

/**
 * Recalculates the height and sets the trigger top value based on ad conditions.
 *
 * @param {object} params - The parameters for the function.
 * @param {object} params.ref - The reference to the DOM element.
 * @param {Function} params.setTriggerTop - The function to set the trigger top value.
 */
export const recalculateHeight = ({
  ref,
  setTriggerTop,
}) => {
  console.log('recalculateHeight called');
  if (ref?.current?.getBoundingClientRect) {
    const { top } = ref.current.getBoundingClientRect();
    const smallAd = Breakpoints.isS() && window.pageYOffset <= 70;
    const medAd = Breakpoints.isM() && window.pageYOffset <= 122;
    const largeAd = Breakpoints.isL() && window.pageYOffset <= 282;
    if (smallAd || medAd || largeAd) {
      setTriggerTop(top + window.pageYOffset); // change triggerTop according to min height of ads on diff screen size
    }
  }
};

/**
 * Inserts the Save component into the DOM if the conditions are met.
 *
 * @param {object} params - The parameters for the function.
 * @param {object} params.ref - The reference to the DOM element.
 * @param {object} params.state - The state object.
 * @param {string} params.pageView - The current page view.
 * @param {string} params.vertical - The current vertical.
 * @param {string} params.contentId - The content ID.
 * @returns {React.ReactPortal|null} The Save component portal or null.
 */
export const insertSaveArticle = ({
  ref,
  state,
  pageView,
  vertical,
  contentId,
}) => {
  const isArticle = pageView === 'article';
  const savingArticleEnabled = getFeatureConfigForBrand(MYNEWS_ENABLED, vertical);
  const isToday = vertical === 'today';
  if (!isToday && isArticle && savingArticleEnabled) {
    if (state.ready && ref?.current?.querySelector) {
      const container = ref.current.querySelector('.share-ul');
      const saveNavContainer = document.createElement('div');
      saveNavContainer.setAttribute('tabindex', '-1');
      container.appendChild(saveNavContainer);
      return ReactDOM.createPortal(<Save contentId={contentId} contentType="article" navbarPlacement />, saveNavContainer);
    }
  }
  return null;
};

const mapActionsToProps = {
  setTriggerTop: setTriggerTopAction,
  updateNavConfig: bulkUpdate,
};

const ServiceHeader = (props) => {
  const {
    adsEnabled = true,
    currentPath = '/',
    header,
    headline = null,
    pageView = null,
    shareUrl = null,
    shortcuts,
    taxonomy = null,
    contentId = null,
    updateNavConfig = Function.prototype,
    vertical,
    isNavbarSticky = true,
    cookies,
    isElectionsNavEnabled = false,
    setTriggerTop,
  } = props;

  const context = useContext(FeatureFlagContext);
  const wrapperRef = useRef(null);
  const [state, setState] = useState({
    ready: false,
    isShowBreakingNewsDigest: false,
    isUSA: false,
    isMobile: false,
  });
  let parsedNavbar = null;

  const {
    featureFlagQueryParam,
    'account-login': accountLogin,
  } = context;

  const onBreakpointChange = () => {
    setState((prevState) => ({ ...prevState, isMobile: Breakpoints.isS() }));
  };

  const hasHeader = () => header?.html?.length;


  const insertElectionsNav = () => {
    if (isElectionsNavEnabled) {
      if (state.ready && wrapperRef?.current?.querySelector) {
        const container = wrapperRef.current.querySelector('.hfsh');
        const electionsNavContainer = document.createElement('div');
        electionsNavContainer.setAttribute('class', 'elections-nav-wrapper');
        container.appendChild(electionsNavContainer);

        const electionsNavTheme = getFeatureConfigForBrand(ENABLE_ELECTIONS_NAV_THEME, vertical);

        if (!electionsNavTheme) return null;

        const { yearPosition } = electionsNavTheme;
        const year = taxonomy?.split('-')[yearPosition];

        return ReactDOM.createPortal(
          <ElectionsNav year={year} />,
          electionsNavContainer,
        );
      }
    }
    return null;
  };


  const renderLocalInsert = (showMiniTease) => {
    let selector = '.js-global-nav-insert';
    let callback = () => onGlobalInsertShow(updateNavConfig, window);
    const { 'trending-nav': hasTrending } = context;
    const trendingNavEnabled = hasTrending && getFeatureConfigForBrand(TRENDING_NAV, vertical);
    if (state.ready && wrapperRef?.current?.querySelector) {
      if (hasTeaseBelowNav({
        vertical,
        pageView,
        currentPath,
      })) {
        selector = '.js-portal-below-header';
        callback = () => { };
      }

      const localNavInsertOverride = getFeatureConfigForBrand(LOCAL_NAV_INSERT_OVERRIDE, vertical);

      if (hasSingleNav(vertical, featureFlagQueryParam) || localNavInsertOverride) {
        selector = '.js-local-nav-insert';
        callback = () => onLocalInsertShow(updateNavConfig, window);
      }

      const container = wrapperRef?.current?.querySelector(selector);

      if (container) {
        if (trendingNavEnabled) {
          return ReactDOM.createPortal(
            <TrendingNav />,
            container,
          );
        }
        if (showMiniTease) {
          return ReactDOM.createPortal(
            <MiniPlayerTease
              useLocalTease={hasSingleNav(vertical, featureFlagQueryParam)}
              onShowTease={callback}
            />,
            container,
          );
        }
      }
    }
    return null;
  };

  const insertSponsorAd = () => {
    if (pageView === 'myNews') {
      return null;
    }

    if (
      state.ready
      && wrapperRef?.current?.querySelector
      && adsEnabled
      && vertical !== 'sponsoredcontent'
    ) {
      const container = wrapperRef.current.querySelector('.js-sponsor-wrap');
      if (container) {
        return ReactDOM.createPortal(<AdSponsor />, container);
      }
    }
    return null;
  };

  /**
   * As of 1/30/25 the only use for getting the class list is for Telemundo's showing/hiding
   * of the global nav. See method hasGlobalNav() below.
   */
  const getParsedMarkup = () => {
    if (parsedNavbar === null) {
      const {
        header: {
          html: [html],
        } = {},
      } = props;
      // Use regex to find the first <nav> element and extract its class attribute
      const navClassMatch = html.match(/<nav[^>]*class="([^"]*)"/);
      if (navClassMatch) {
        const classList = navClassMatch[1].split(/\s+/);
        parsedNavbar = { classNames: classList };
      } else {
        parsedNavbar = false;
      }
    }
    return parsedNavbar;
  };

  /**
   * TODO (BENTO-13916): separate inner navbar markup and configuration at HFS level
   * Extract inner html from HFS navbar <nav> markup.
   * @param {string} html
   */
  const getProcessedMarkup = (html) => {
    let markupString = html;
    // Remove the first opening <nav> tag
    markupString = markupString.replace(/<nav[^>]*>/, '');
    // Remove the last closing </nav> tag
    markupString = markupString.replace(/<\/nav>(?![\s\S]*<\/nav>)/, '');
    return markupString;
  };

  /**
   * Check classes on navbar element for given class.
   * @param {string} className
   */
  const hasConfiguredNavClass = (className) => {
    const {
      classNames: navClassNames = [],
    } = getParsedMarkup() || {};
    return navClassNames.includes(className);
  };

  const hasGlobalNav = () => {
    if (isElectionsNavEnabled && getFeatureConfigForBrand(ENABLE_ELECTIONS_NAV_THEME, vertical)) {
      return false;
    }
    if (hasSingleNav(vertical, featureFlagQueryParam)) {
      return false;
    }
    if (!hasConfiguredNavClass('show-global')) {
      return false;
    }
    return true;
  };

  useLayoutEffect(() => {
    const handleRecalculateHeight = () => {
      recalculateHeight({ ref: wrapperRef, setTriggerTop });
    };
    if (hasHeader() && window) {
      const queryParameters = new URLSearchParams(window.location.search);
      const experimentQueryParam = queryParameters.get('ex');

      let showBreakingNewsDigest = false;
      if (vertical === 'news') {
        showBreakingNewsDigest = experimentQueryParam === 'digest';
      }

      const cookieValue = cookies.get(GEOLOCATION_COOKIE);
      const isUSA = cookieValue === USA;

      setState((prevState) => ({
        ...prevState,
        isMobile: Breakpoints.isS(),
        isUSA,
        isShowBreakingNewsDigest: showBreakingNewsDigest,
      }));

      const brandFeatureFlag = getFeatureConfigForBrand(
        BEDROCK_API_ENABLED,
        vertical,
      );
      const enableBedrock = accountLogin && brandFeatureFlag;

      const {
        js: {
          src = [],
        },
      } = header;

      // Set brand/vertical in config object
      if (typeof window.HFSconfig === 'undefined') {
        window.HFSapi = {};
        window.HFSconfig = {
          brand: vertical,
          header: {
            primary: get(headline, 'primary'),
            social: get(headline, 'social'),
            url: getSocialShareUrl(shareUrl, vertical),
            shortcuts,
            showBreakingNewsDigest,
            enableIdentity: !!getFeatureConfigForBrand(MYNEWS_ENABLED, vertical),
            enableBedrock,
          },
          pageView,
        };

        if (!hasGlobalNav()) {
          window.HFSconfig.header.globalNav = false;
        }

        if (hasSingleNav(vertical, featureFlagQueryParam)) {
          window.HFSconfig.header.useSmallNav = true;
        }
      }

      let scriptsLoaded = 0;
      // Attach scripts
      src.forEach((script) => {
        loadScript(script)
          .then(() => {
            scriptsLoaded += 1;
            // Fire window event to initialize header
            if (scriptsLoaded >= src.length) {
              const event = new CustomEvent('HFS.ready', { detail: 'header' });
              window.dispatchEvent(event);
              // Set ready
              setState((prevState) => ({ ...prevState, ready: true }));
            }
          });
      });

      // Observe height changes
      window.addEventListener('HFS.recalculateHeight', handleRecalculateHeight);
      Breakpoints.getSmallMQL()?.addListener(onBreakpointChange);
    }

    return () => {
      window.removeEventListener('HFS.recalculateHeight', handleRecalculateHeight);
      Breakpoints.getSmallMQL()?.removeListener(onBreakpointChange);
    };
  }, []);

  if (!hasHeader()) {
    return <HeaderFallback />;
  }

  const {
    header: {
      js: { html: scripts },
      html: [html],
    } = {},
  } = props;

  const {
    isUSA, isShowBreakingNewsDigest, isMobile,
  } = state;
  const showMiniTease = isShowBreakingNewsDigest ? !isMobile : true;
  const identityEnabled = !!getFeatureConfigForBrand(MYNEWS_ENABLED, vertical);
  const showIdentity = isUSA && identityEnabled;
  const hasMobileNavInsert = vertical === 'news' ? getFeatureConfigForBrand(SHOW_MOBILE_WEB_NAV, vertical) && currentPath === '/' : getFeatureConfigForBrand(SHOW_MOBILE_WEB_NAV, vertical);
  const isElectionNavEnabledForBrand = isElectionsNavEnabled
    && getFeatureConfigForBrand(ENABLE_ELECTIONS_NAV_THEME, vertical);

  let showPolitics = false;

  if (isElectionNavEnabledForBrand) {
    showPolitics = true;
  }

  return (
    <>
      {scripts && Array.isArray(scripts) && scripts.map((src) => (
        <Script
          key={src.replace(/\s+/g, '').substr(0, 10)}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: decodeURIComponent(src) }}
        />
      ))}

      <div
        className={classNames(
          'layout-header',
          { 'show-elections-nav': isElectionNavEnabledForBrand },
          { notSticky: !isNavbarSticky },
        )}
        id="hfs-header"
        data-activity-map="hfs-header"
        ref={wrapperRef}
      >
        <SkipToContentOverlay />
        <HeaderNavbar
          html={getProcessedMarkup(html)}
          showGlobal={hasGlobalNav()}
          hasMobileNavInsert={hasMobileNavInsert}
          showIdentity={showIdentity}
          showPolitics={showPolitics}
          useSmallNav={hasSingleNav(vertical, featureFlagQueryParam)}
        />
        {isNavbarSticky && (
          <div className="hfsh-spacer" />
        )}

        {/* Render tease portal */}
        {renderLocalInsert(showMiniTease)}

        {/* Render sponsorAd portal */}
        {insertSponsorAd()}

        {insertElectionsNav()}
        {isUSA && insertSaveArticle({
          ref: wrapperRef,
          state,
          pageView,
          vertical,
          contentId,
        })}
      </div>

      <div className="js-portal-below-header" />
      <div id="header-end" tabIndex={-1} />
    </>
  );
};

ServiceHeader.propTypes = {
  adsEnabled: PropTypes.bool,
  currentPath: PropTypes.string,
  header: PropTypes.shape({
    css: PropTypes.shape({}),
    js: PropTypes.shape({
      src: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }),
    html: PropTypes.arrayOf(
      PropTypes.string,
    ),
  }).isRequired,
  headline: HeadlinePropType,
  pageView: PropTypes.string,
  setTriggerTop: PropTypes.func.isRequired,
  shareUrl: PropTypes.string,
  shortcuts: PropTypes.bool.isRequired,
  taxonomy: PropTypes.string,
  contentId: PropTypes.string,
  updateNavConfig: PropTypes.func,
  vertical: PropTypes.string.isRequired,
  isNavbarSticky: PropTypes.bool,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  isElectionsNavEnabled: PropTypes.bool,
};

export default connect(mapStateToProps, mapActionsToProps)(
  withCookies(ServiceHeader),
);
