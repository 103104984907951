import { stripQueryParams } from 'lib/urlUtils';
import Breakpoints from 'lib/Breakpoints';

/**
 * Test unique case(s) where mini tease appears in non-standard position.
 *
 * @param {object} params - The parameters for the function.
 * @param {string} params.vertical - The vertical type.
 * @param {string} params.pageView - The current page view.
 * @param {string} params.currentPath - The current path.
 * @returns {boolean} - Returns true if the mini tease should appear below the nav, otherwise false.
 */
export const hasTeaseBelowNav = ({
  vertical = '',
  pageView = '',
  currentPath = '',
} = {}) => (
  vertical === 'msnbc'
    && pageView === 'front'
    && stripQueryParams(currentPath) === '/'
    && Breakpoints.isS()
);

/**
 * Updates the navbar configuration and enables local insert via HFS API.
 *
 * @param {Function} updateNavConfig - The function to update the navbar configuration.
 * @param {object} window - The global window object.
 */
export const onLocalInsertShow = (updateNavConfig, window) => {
  // eslint-disable-next-line max-len
  if (typeof window === 'object' && window.HFSapi && typeof window.HFSapi.enableLocalInsert === 'function') {
    // Update stored navbar configuration
    updateNavConfig({ hasLocalInsert: true, useSmallNav: true });

    // Enable global insert via HFS API
    window.HFSapi.enableLocalInsert();
  }
};


/**
 * Updates the navbar configuration and enables global insert via HFS API.
 *
 * @param {Function} updateNavConfig - The function to update the navbar configuration.
 * @param {object} window - The global window object.
 *
 */
export const onGlobalInsertShow = (updateNavConfig, window) => {
  if (window?.HFSapi && typeof window.HFSapi.enableGlobalInsert === 'function') {
    // Update stored navbar configuration
    updateNavConfig({ hasGlobalInsert: true });
    // Enable global insert via HFS API
    window.HFSapi.enableGlobalInsert();
  }
};
